<template>
  <div
    class="q-pa-sm full-height full-width column no-wrap itemcard"
    :class="{
      selectedcard: selected === true,
      hovercard: over === true && selected === false,
    }"
    style="
      display: inline-block; 
      border-radius: 1rem
    "
    @click.stop="selectItem()"
    @mouseover="overItem()"
    @mouseleave="leaveItem()"
  >
    <div class="row">
      <!-- Image -->
      <div class="col-auto q-mr-md">
        <q-avatar
          size="10rem"
          style="border-radius: 0.75rem"
          class="bg-1"
        >
          <pickerimage
            :data="{
              uid: 'classroom_card_img_' + item.ID,
              objectid: item.ID,
              objecttype: 'classroom_picture',
            }"
            :view="{
              onlyshow: true,
            }"
            class="full-width full-height"
          />
        </q-avatar>
      </div>

      <!-- Metadata -->
      <div class="col">
        <!-- Title -->
        <div 
          class="row q-mb-xs items-center"
          style="
            height: 2.25rem;
            font-size: 1.75rem;
            font-weight: bold;
            -webkit-text-fill-color: var(--q-e);
            paint-order: stroke fill;
            overflow: hidden;
          "
        >
          {{ item.Name }}

          <q-tooltip
            anchor="top right"
            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
            self="bottom right"
            transition-hide="jump-left"
            transition-show="jump-right"
          >
            {{ item.Name }}
          </q-tooltip>
        </div>

        <!-- Classroom Info -->
        <div 
          class="row q-mb-xs"
          style="
            font-size: 0.75rem;
            -webkit-text-fill-color: var(--q-5);
            overflow: hidden;
            height: 1.15rem;
          "
        >
          <!-- Classroom Members -->
          <div 
            class="col-auto q-mr-md"
            style=""
          >
            <span style="font-weight: bold">
              {{ $t("-raw-members") }}:
            </span>
            {{ item.Members }}
          </div>

          <!-- Classroom Plans -->
          <div
            class="col-auto q-mr-md"
            style="">
            <span style="font-weight: bold">
              {{ $t("-raw-plans") }}: 
            </span>
            {{ item.Plans }}
          </div>

          <!-- Classroom Languages -->
          <div
            class="col"
            v-if="
              item.LanguageIDs &&
              Array.isArray(item.LanguageIDs) &&
              item.LanguageIDs.length > 0
            " 
          >
            <span style="font-weight: bold"
              >{{
                $t("-raw-languages") === "-raw-languages"
                  ? "Languages"
                  : $t("-raw-languages")
              }}:
            </span>
            <template
              v-for="(lng1, n1) in item.LanguageIDs"
              :key="n1 + 'classroom_languages_list1'"
            >
              <template
                v-for="(lng2, n2) in languages"
                :key="n2 + 'classroom_languages_list2'"
              >
                <template v-if="lng1 === lng2.ID">
                  {{ lng2.name }};
                </template>
              </template>
            </template>
          </div>
        </div>

        <!-- Description -->
        <div
          class="row q-mb-sm"
          style="
            -webkit-text-fill-color: var(--q-9);
            height: 3.75rem;
          "
        >   
          <q-scroll-area
            class="full-height full-width"
          >
            {{ item.Description }}
          </q-scroll-area>
        </div>

        <!-- Tags & Icon -->
        <div 
          class="row q-pt-xs"
          style="
            height: 1.75rem;
            overflow: hidden;
          "
        >
          <!-- Classroom Label -->
          <div class="col-auto q-mr-sm">
            <q-badge 
              rounded 
              color="h"
              style="
                -webkit-text-fill-color: var(--q-0);
              "
            >
              {{ $t("route-classroom") }}
            </q-badge>
          </div>

          <!-- Featured Content Label -->
          <div class="col-auto q-mr-sm">
            <q-badge 
              rounded 
              color="vv"
              style="
                -webkit-text-fill-color: var(--q-0);
              "
            >
              {{ $t("-raw-discovery-featured") }}
            </q-badge>
          </div>

          <!-- User Ratings -->
          <div class="col-auto q-mr-sm">
            <q-badge 
              rounded
              color="p"
              style="
                -webkit-text-fill-color: var(--q-0);
              "
              v-if="item.Rating"
            >
              {{ item.Rating.toFixed(1)
              }}<span >/{{ "5.0" }}</span>
              <q-icon name="star" />
              <q-tooltip
                anchor="top middle"
                self="bottom middle"
                class="bg-3 text-8 round-both"
                transition-show="jump-up"
                transition-hide="jump-down"
              >
                <b
                  >{{ $t("-raw-challenge-rated-by") }} {{ item.CountRating }}
                  {{ $t("-raw-challenge-rated-by-users") }}</b
                >
              </q-tooltip>
            </q-badge>
          </div>

          <!-- Classroom Icon -->
          <div class="col">
            <q-icon
              color="v"
              size="1.75rem"
              class=""
              style="float: right"
              name="handshake"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.itemcard {
  border: 1px solid var(--q-2);
  transition: all 0.2s ease;
  user-select: none;
  cursor: pointer;
}
.hovercard {
  border: 1px solid var(--q-v);
  scale: 1.025;
}
.selectedcard {
  border: 1px solid var(--q-p);
  scale: 0.975;
  transition: all 0.075s ease;
}
</style>

<script>
import pickerimage from "src/components/picker-picture/picker.vue";

export default {
  props: ["item"],
  components: { pickerimage },
  data: () => ({
    selected: false,
    over: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    languages() {
      return this.$store.getters.languages;
    },
  },
  methods: {
    selectItem() {
      this.selected = true;
      this.$router.push("/classroom/" + this.item.ID);
    },
    overItem() {
      this.over = true;
    },
    leaveItem() {
      this.over = false;
    },
  },
};
</script>
