<template>
  <div
    class="q-pa-sm full-height full-width column no-wrap itemcard"
    :class="{
      selectedcard: selected === true,
      hovercard: over === true && selected === false,
    }"
    style="
      display: inline-block;
      border-radius: 1rem;
    "
    @click.stop="selectItem()"
    @mouseover="overItem()"
    @mouseleave="leaveItem()"
  >
    <div 
      class="row items-center q-mb-sm"
      style="
        overflow: hidden;
        white-space: nowrap;
      "
    > 
      <!-- Image -->
      <div 
        class="col"
      >
        <q-avatar
          size="24rem"
          style="border-radius: 0.75rem"
          class="bg-1"
        >
          <pickerimage
            :data="{
              uid: 'story_card_img_' + item.ID,
              objectid: item.ID,
              objecttype: 'story_picture',
            }"
            :view="{
              onlyshow: true,
            }"
            class="full-width full-height"
          />
        </q-avatar>
      </div>
    </div>

    <div 
      class="row items-center q-mb-xs"
      style="
        height: 2.5rem;
        overflow: hidden;
        white-space: nowrap;
      "
    >
      <!-- Title -->    
      <div
        class="col q-mr-md"
        style="
          font-size: 1.75rem;
          font-weight: bold;
          -webkit-text-fill-color: var(--q-e);
          paint-order: stroke fill;
        "
      >
        {{ item.Name }}

        <q-tooltip
          anchor="top right"
          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
          self="bottom right"
          transition-hide="jump-left"
          transition-show="jump-right"
        >
          {{ item.Name }}
        </q-tooltip>
      </div>
    </div>

    <div 
      class="row items-center q-mb-xs"
      style="
        -webkit-text-fill-color: var(--q-5);
        font-size: 0.75rem;
        height: 1.5rem;
        overflow: hidden;
        white-space: nowrap;
      "
    >
      <!-- Channel Picture -->
      <div 
        class="col-auto q-mr-sm"
      >
        <q-avatar
          size="1.5rem"
          style="border-radius: 0.75rem"
          class="bg-1"
        >
          <pickerimage
            v-if="item && item.ID"
            :data="{
              uid: 'channel_img_' + item.ID,
              objectid: item.ChannelID,
              objecttype: 'channel_avatar',
            }"
            :view="{
              onlyshow: true,
            }"
            class="full-width"
          />
        </q-avatar>
      </div>
      
      <!-- Channel Name -->
      <div 
        class="col-auto q-mr-md"
        style="
          font-weight: bold;
        "
      >
        {{ item.ChannelName.substring(0, 20) }}

        <q-tooltip
          anchor="top right"
          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
          self="bottom right"
          transition-hide="jump-left"
          transition-show="jump-right"
        >
          {{ item.ChannelName }}
        </q-tooltip>
      </div>

      <!-- Languages -->
      <div
        class="col"
        v-if="
          storylangs &&
          Array.isArray(storylangs) &&
          storylangs.length > 0
        "         
      >
        <span style="font-weight: bold">
          {{ $t("-raw-languages") }}:
        </span>

        <template
          v-for="(lng1, n1) in storylangs"
          :key="n1 + 'story_languages_list1'"
        >
          <template
            v-for="(lng2, n2) in languages"
            :key="n2 + 'story_languages_list2'"
          >
            <template v-if="lng1 === lng2.ID"> 
              {{ lng2.name }}; 
            </template>
          </template>
        </template>
      </div>
    </div>

    <div 
      class="row items-center q-pt-xs"
      style="
        height: 1.95rem;
        overflow: hidden;
        white-space: nowrap;
      "
    >
      <!-- Story Label -->
      <div 
        class="col-auto q-mr-sm"
      >
        <q-badge 
          rounded 
          color="n"
          style="
            -webkit-text-fill-color: var(--q-0);
          "
        >
        {{ $t("-raw-route-story") }}
        </q-badge>
      </div>

      <!-- Recommended Label -->
      <div 
        class="col-auto q-mr-sm"
      >
        <q-badge 
          rounded 
          color="ww"
          style="
            -webkit-text-fill-color: var(--q-0);
          "
        >
        {{ $t("-raw-discovery-recommended") }}
        </q-badge>
      </div>

      <!-- User Ratings -->
      <div 
        class="col-auto q-mr-sm"
      >
        <q-badge
          rounded
          color="p"
          style="
            -webkit-text-fill-color: var(--q-0);
          "
          v-if="item.Rating"
        >
          {{ item.Rating.toFixed(1)
          }}<span >/{{ "5.0" }}</span>
          <q-icon name="star" />
          <q-tooltip
            anchor="top middle"
            self="bottom middle"
            class="bg-3 text-8 round-both"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
            <b
              >{{ $t("-raw-challenge-rated-by") }} {{ item.CountRating }}
              {{ $t("-raw-challenge-rated-by-users") }}</b
            >
          </q-tooltip>
        </q-badge>
      </div>

    <!-- Story Icon -->
    <div 
        class="col"
      >
        <q-icon
          color="v"
          size="1.75rem"
          class=""
          style="float: right"
          name="theater_comedy"
        />
      </div>
    </div>  
  </div>
</template>

<style scoped>
.itemcard {
  border: 1px solid var(--q-2);
  transition: all 0.2s ease;
  user-select: none;
  cursor: pointer;
}
.hovercard {
  border: 1px solid var(--q-v);
  scale: 1.025;
}
.selectedcard {
  border: 1px solid var(--q-p);
  scale: 0.975;
}
</style>

<script>
import pickerimage from "src/components/picker-picture/picker.vue";
import api from "src/h/api.js";

export default {
  components: { pickerimage },
  props: ["item"],
  data: () => ({
    selected: false,
    over: false,
    storylangs: [],
  }),
  created() {
    this.storyLangs();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    languages() {
      return this.$store.getters.languages;
    },
  },
  methods: {
    selectItem() {
      this.selected = true;
      this.$router.push("/story-pass/" + this.item.ID);
    },
    overItem() {
      this.over = true;
    },
    leaveItem() {
      this.over = false;
    },
    // Fetch story languages: Recommended api call on discovery page doesn't return LanguageIDs 
    // array for stories, but does so for playlists and returns LanguageID for challenges
    storyLangs() {
      api
        .Call({
          url: "/api/v1/story/" + this.item.ID,
          method: "get",
          show_error: false,
        })
        .then(
          (result) => {
            this.storylangs = result.LanguageIDs;
          },
          (e) => {
            console.error("Error fetching classroom data", e);
          },
        );
    },
  },
};
</script>