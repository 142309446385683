<template>
  <div
    class="q-pa-sm full-height full-width column no-wrap playlist bg-0"
    style="
      border-radius: 1rem;
      border: 1px solid var(--q-2);
      display: inline-block;
      position: relative;
    "
    @click="$emit('openPlaylist')"
  >
    <!-- purple playlist icon top right -->
    <q-icon
      name="playlist_play"
      class="text-v"
      size="2rem"
      style="position: absolute; top: 0.5rem; right: 0.35rem"
    />
    <!-- purple public icon top right -->
    <q-icon
      color="v"
      size="1rem"
      class="q-pa-xs bg-0 round-both"
      style="position: absolute; top: 2.5rem; right: 0.5rem"
      :name="
        item.Privacy === 'public'
          ? 'public'
          : item.Privacy === 'unlisted'
            ? 'link'
            : 'public_off'
      "
    >
      <q-tooltip
        anchor="top middle"
        self="bottom middle"
        class="bg-2 text-8 round-both"
        transition-show="jump-up"
        transition-hide="jump-down"
      >
        <b>{{
          item.Privacy === "public"
            ? $t("-raw-playlist-privacy-public")
            : item.Privacy === "unlisted"
              ? $t("-raw-playlist-privacy-unlisted")
              : $t("-raw-playlist-privacy-private")
        }}</b>
      </q-tooltip>
    </q-icon>
    <!-- green play button bottom right -->
    <q-icon
      v-show="item.AudioSampleRefIDFirst && previewEnabled"
      color="p"
      size="2rem"
      class="bg-0 round-1 q-pa-xs"
      style="
        border: 1px solid var(--q-2);
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
      "
      name="volume_up"
      @click.stop="!previewLoading && $emit('preview')"
    >
      <q-tooltip
        anchor="top middle"
        self="bottom middle"
        class="bg-2 text-8 round-both"
        transition-show="jump-up"
        transition-hide="jump-down"
      >
        <b>{{ $t("-raw-preview") }}</b>
      </q-tooltip>
      <q-inner-loading :showing="previewLoading" class="bg-0" size="1.5rem" />
    </q-icon>
    <!-- image and languages -->
    <div class="row no-wrap" style="max-width: 90%">
      <div
        class="bg-2 round-1"
        style="
          aspect-ratio: 1/1;
          width: 10rem;
          min-width: 10rem;
          border: 1px solid var(--q-2);
          position: relative;
        "
        :style="{
          'background-repeat': 'no-repeat',
          'background-size': 'contain',
          'background-position': 'center center',
          'background-image': 'url(' + handlePlaylistPicture(item) + ')',
        }"
      >
        <q-icon
          name="playlist_play"
          class="text-0"
          size="5rem"
          style="
            top: 50%;
            left: 50%;
            opacity: 0.5;
            position: absolute;
            transform: translate(-50%, -50%);
          "
        />
      </div>
      <div class="column q-pl-md">
        <!-- author stuff -->
        <div class="row no-wrap items-center q-gutter-x-sm q-mb-sm">
          <div v-if="item.Rating" class="q-mr-sm text-no-wrap">
            <q-icon name="star" />
            {{ item.Rating.toFixed(1) }}
            <span style="font-size: 0.6rem">/{{ item.CountRating }}</span>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-4 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b
                >{{ $t("-raw-challenge-rated-by") }} {{ item.CountRating }}
                {{ $t("-raw-challenge-rated-by-users") }}</b
              >
            </q-tooltip>
          </div>
          <q-icon
            @click.stop="$store.dispatch('getChannels'), (prompt = true)"
            flat
            color="v"
            size="1.25rem"
            class="q-my-auto bg-2"
            name="content_copy"
            style="
              width: 2.5rem;
              height: 2.5rem;
              position: relative;
              border-radius: 0.75rem;
            "
            v-if="item.Access?.delete === true"
          >
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>{{ $t("-raw-playlistcard-copy") }}</b>
            </q-tooltip>
          </q-icon>
          <q-dialog v-model="prompt">
            <q-card style="min-width: 350px">
              <q-card-section>
                <div class="text-h6">
                  {{ $t("-raw-challengecard-select-channel") }}
                </div>
              </q-card-section>

              <q-card-section class="q-pt-none row q-gutter-md">
                <mychannels
                  @select="
                    (c) => {
                      channelCopyTo = c;
                    }
                  "
                  :selected="channelCopyTo"
                  :options="
                    $store.state.channels.channels.filter(
                      (c) => c.ID !== $store.getters.currentchannel.ID,
                    )
                  "
                />
              </q-card-section>

              <q-card-actions :align="right" class="text-primary">
                <q-btn flat :label="$t('-raw-general-cancel')" v-close-popup />
                <q-btn
                  flat
                  label="Copy"
                  v-if="channelCopyTo"
                  :loading="copying"
                  @click="copyToChannel(item.ID, channelCopyTo.ID)"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-icon
            flat
            color="8"
            size="1.25rem"
            class="bg-2"
            name="delete"
            style="
              width: 2.5rem;
              height: 2.5rem;
              position: relative;
              border-radius: 0.75rem;
            "
            @click.stop="deletePlaylist(item.ID)"
            v-if="item.Access?.update === true"
          >
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>{{ $t("-raw-tooltip-delete") }}</b>
            </q-tooltip>
          </q-icon>
        </div>
        <!-- non-author stuff -->
        <div class="q-mt-sm">
          <span style="font-weight: bold">
            {{ $t("-raw-channel-summary-total-count") }}:</span
          >
          {{
            item?.ItemCount > 0
              ? item.ItemCount + " items"
              : $t("-raw-term-empty")
          }}
        </div>
        <div
          v-if="
            item.LanguageIDs &&
            Array.isArray(item.LanguageIDs) &&
            item.LanguageIDs.length > 0
          "
        >
          <span style="font-weight: bold">{{ $t("-raw-languages") }}: </span>
          <template
            v-for="(lng1, n1) in item.LanguageIDs"
            :key="n1 + 'playlist_languages_list1'"
          >
            <template
              v-for="(lng2, n2) in $store.getters.languages"
              :key="n2 + 'playlist_languages_list2'"
            >
              <template v-if="lng1 === lng2.ID"> {{ lng2.name }}; </template>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="row text-9 q-pt-md q-pb-sm q-pl-sm">
      <q-avatar
        style="
          width: 3rem;
          height: 3rem;
          overflow: hidden;
          border: 1px solid var(--q-2);
        "
        class="round-1 bg-1"
        size="3rem"
        color="1"
      >
        <pickerimage
          v-if="item && item.ID"
          :data="{
            uid: 'channel_img_' + item.ID,
            objectid: item.ChannelID,
            objecttype: 'channel_avatar',
          }"
          :view="{
            onlyshow: true,
          }"
          class="bg-transparent full-width"
        />
      </q-avatar>
      <div class="col column no-wrap text-9 q-pl-md">
        <div
          class="q-mb-xs text-9"
          style="
            font-size: 1.3rem;
            font-weight: bold;
            line-height: 1.5rem;
            width: 90%;
          "
        >
          {{ item.Name }}
        </div>
        <div v-if="$route.path === '/' || showInfo" class="col column text-8">
          {{
            item?.ChannelName?.length > 20
              ? `${item?.ChannelName.slice(0, 19)}...`
              : item?.ChannelName
          }}
          &middot; {{ item?.UserName }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.playlist {
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}
.playlist:hover {
  border: 1px solid var(--q-v) !important;
}
</style>

<script>
import mychannels from "src/components/mychannels.vue";
import api from "src/h/api.js";
import pickerimage from "src/components/picker-picture/picker.vue";
import { mapGetters } from "vuex";

export default {
  components: { mychannels, pickerimage },
  props: ["item", "previewEnabled", "showInfo"],
  emits: ["openPlaylist", "deletePlaylist", "preview"],
  data: () => ({
    copying: false,
    prompt: false,
    channelCopyTo: null,
    previewLoading: false,
  }),
  computed: {
    ...mapGetters(["currentchannel", "user"]),
  },
  methods: {
    copyToChannel(playlistID, channelID) {
      this.copying = true;

      api
        .Call({
          url: "/api/v1/playlist/clone",
          method: "post",
          data: {
            DestinationChannelID: channelID,
            PlaylistID: playlistID,
          },
          show_error: true,
        })
        .then(
          (response) => {
            this.copying = false;
            this.prompt = false;
            // this.$q.notify({
            //   type: "positive",
            //   message: "Successfully copied",
            //   classes: "round-both q-mb-sm no-shadow ubuntu",
            // });
            this.$router.push("/playlist/" + response.ID);
          },
          (e) => {
            this.copying = false;
            this.prompt = false;
          },
        );
    },
    handleDate(datesrc) {
      const date = new Date(datesrc);
      return date.toDateString();
    },
    deletePlaylist(id) {
      this.$emit("deletePlaylist", id);
    },
    handlePlaylistPicture(playlist) {
      if (playlist.PictureIDFirst) {
        return (
          process.env.PUBLIC_API_URL +
          "/api/v1/asset/file/challenge_picture/" +
          playlist.PictureIDFirst
        );
      }
    },
  },
};
</script>
