<template>
  <div
    class="q-pa-sm full-height full-width column no-wrap itemcard"
    :class="{
      selectedcard: selected === true,
      hovercard: over === true && selected === false,
    }"
    style="
      display: inline-block;
      border-radius: 1rem;
    "
    @click.stop="selectItem()"
    @mouseover="overItem()"
    @mouseleave="leaveItem()"
  >
    <div 
      class="row items-center q-mb-sm"
      style="
        overflow: hidden;
        white-space: nowrap;
      "
    > 
      <!-- Image -->
      <div 
        class="col"
      >
        <q-avatar
          size="24rem"
          style="border-radius: 0.75rem"
          class="bg-1"
        >
          <pickerimage
            :data="{
              uid: 'challenge_card_img_' + item.ID,
              objectid: item.ID,
              objecttype: 'challenge_picture',
            }"
            :view="{
              onlyshow: true,
            }"
            class="full-width full-height"
          />
        </q-avatar>
      </div>
    </div>

    <div 
      class="row items-center q-mb-xs"
      style="
        height: 2.5rem;
        overflow: hidden;
        white-space: nowrap;
      "
    >
      <!-- Title -->    
      <div 
        class="col q-mr-md"
        style="
          font-size: 1.75rem;
          font-weight: bold;
          -webkit-text-fill-color: var(--q-e);
          paint-order: stroke fill;
          overflow: hidden;
        "
      >
        {{ item.Name }}

        <q-tooltip
          anchor="top right"
          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
          self="bottom right"
          transition-hide="jump-left"
          transition-show="jump-right"
        >
          {{ item.Name }}
        </q-tooltip>
      </div>

      <!-- Preview Button -->
      <div  
        class="col-auto"
        style=""
      >
        <q-icon
          v-show="
            !opening && previewEnabled && item.AudioSampleRefID
          "
          color="p"
          size="1.65rem"
          name="volume_up"
          class="round-1 q-pa-xs"
          style="
            border: 1px solid var(--q-2);
          "
          @click.stop="!previewLoading && $emit('preview')"
        >
          <q-tooltip
            anchor="top middle"
            self="bottom middle"
            class="bg-2 text-8 round-both"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
            <b>{{
              $t("-raw-preview")
            }}</b>
          </q-tooltip>
          <q-inner-loading :showing="previewLoading" class="bg-0" size="1.5rem" />
        </q-icon>
      </div>
    </div>

    <div 
      class="row items-center q-mb-xs"
      style="
        -webkit-text-fill-color: var(--q-5);
        font-size: 0.75rem;
        height: 1.5rem;
        overflow: hidden;
        white-space: nowrap;
      "
    >
      <!-- Channel Picture -->
      <div 
        class="col-auto q-mr-sm"
      >
        <q-avatar
          size="1.5rem"
          style="border-radius: 0.75rem"
          class="bg-1"
        >
          <pickerimage
            v-if="item && item.ID"
            :data="{
              uid: 'channel_img_' + item.ID,
              objectid: item.ChannelID,
              objecttype: 'channel_avatar',
            }"
            :view="{
              onlyshow: true,
            }"
            class="full-width"
          />
        </q-avatar>
      </div>

      <!-- Channel Name -->
      <div 
        class="col-auto q-mr-md"
        style="
          font-weight: bold;
        "
      >
        {{ item.ChannelName.substring(0, 20) }}

        <q-tooltip
          anchor="top right"
          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
          self="bottom right"
          transition-hide="jump-left"
          transition-show="jump-right"
        >
          {{ item.ChannelName }}
        </q-tooltip>
      </div>

      <!-- Language -->
      <div 
        class="col"
        v-if="item.LanguageID"
      >
        <span style="font-weight: bold">
          {{ $t("-raw-challenge-language") }}:
        </span>

        <template
          v-for="(lng, n) in languages"
          :key="n + 'challenge_language_list'"
        >
          <template v-if="item.LanguageID === lng.ID">
            {{ lng.name }}
          </template>
        </template>
      </div>
    </div>

    <div 
      class="row items-center q-pt-xs"
      style="
        height: 1.95rem;
        overflow: hidden;
        white-space: nowrap;
      "
    >
      <!-- Challenge Label -->
      <div 
        class="col-auto q-mr-sm"
      >
        <q-badge 
          rounded 
          color="v"
          style="
            -webkit-text-fill-color: var(--q-0);
          "
        >
          {{ $t("-raw-route-challenge") }}
        </q-badge>
      </div>

      <!-- Recommended Label -->
      <div 
        class="col-auto q-mr-sm"
      >
        <q-badge 
          rounded 
          color="ww"
          style="
            -webkit-text-fill-color: var(--q-0);
          "
        >
          {{ $t("-raw-discovery-recommended") }}
        </q-badge>
      </div>

      <!-- User Ratings -->
      <div 
        class="col-auto q-mr-sm"
      >
        <q-badge 
          rounded
          color="p"
          style="
            -webkit-text-fill-color: var(--q-0);
          "
          v-if="item.Rating"
        >
          {{ item.Rating.toFixed(1)
          }}<span >/{{ "5.0" }}</span>
          <q-icon name="star" />
          <q-tooltip
            anchor="top middle"
            self="bottom middle"
            class="bg-3 text-8 round-both"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
            <b
              >{{ $t("-raw-challenge-rated-by") }} {{ item.CountRating }}
              {{ $t("-raw-challenge-rated-by-users") }}</b
            >
          </q-tooltip>
        </q-badge>
      </div>

      <!-- Challenge Icon -->
      <div 
        class="col"
      >
        <q-icon
          color="v"
          size="1.75rem"
          class=""
          style="float: right"
          name="model_training"
        />
      </div>
    </div>  
  </div>
</template>

<style scoped>
.itemcard {
  border: 1px solid var(--q-2);
  transition: all 0.2s ease;
  user-select: none;
  cursor: pointer;
}
.hovercard {
  border: 1px solid var(--q-v);
  scale: 1.025;
}
.selectedcard {
  border: 1px solid var(--q-p);
  scale: 0.975;
}
</style>

<script>
import pickerimage from "src/components/picker-picture/picker.vue";

export default {
  components: { pickerimage },
  props: [
    "item",
    "previewEnabled",
  ],
  emits: ["preview"],
  data: () => ({
    selected: false,
    over: false,
    opening: false,
    previewLoading: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    languages() {
      return this.$store.getters.languages;
    },
  },
  methods: {
    selectItem() {
      this.opening = true;
      this.selected = true;
      this.$router.push("/challenge/" + this.item.ID);
    },
    overItem() {
      this.over = true;
    },
    leaveItem() {
      this.over = false;
    },
  },
};
</script>